import { useState, useEffect } from "react";
import "./styles.scss";
import calculateTimeRemaining from "./counter_exp";
import logoColor from "../../../assets/home2/Body/logo-color.png"
export default function UkClock() {
  const [time, setTime] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(calculateTimeRemaining);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="numberclock pb-5">
        <div className="ukclock">
          <div className="ukclock_days">
            <div className="ukckockhours">
              {generateSequence(time.days, 4, "days")?.map((item, index) => {
                return (
                  <div className={index == 0 ? "light" : "dark"} key={index}>{item}</div>
                );
              })}
            </div>
          </div>
          <div className="ukckockhours">
            {generateSequence(Number(time.hour) + 2, 4)?.map((item, index) => {
              return (
                <div className={index == 2 ? "light" : "dark"} key={index}>{item}</div>
              );
            })}
          </div>
          <div className="ukclockmin">
            {generateSequence(Number(time.mins) + 3, 4)?.map((item, index) => {
              return (
                <div className={index == 3 ? "light" : "dark"} key={index}>{item}</div>
              );
            })}
          </div>
          <div className="ukclocksec">
            {generateSequence(Number(time.sec) + 1, 4)?.map((item, index) => {
              return (
                <div className={index == 1 ? "light" : "dark"} key={index}>{item}</div>
              );
            })}
          </div>
        </div>

        <div className="smallclock">
          <div className="imgcontainer">
            <img src={logoColor} alt="" />{" "}
          </div>
          <div className="text_and_time">
            <p>38 <span>th</span> National Games 2025</p>
            <div className="timer_clock">
              <div className="timer">
                <p className="timer_time"> {Number(time?.days || "000")}</p>
                <p>DAYS</p>
              </div>
              <div className="timer">
                <p className="timer_time">{time?.hour || "00"}</p>
                <p>HOURS</p>
              </div>
              <div className="timer">
                <p className="timer_time">{time?.mins || "00"}</p>
                <p>MINS</p>
              </div>
              <div className="timer">
                <p className="timer_time">{time.sec || "00"}</p>
                <p>SEC</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function generateSequence(num, length, unit = "sec") {
  const sequence = [];
  if (unit == "days") {
    for (let i = 0; i < length; i++) {
      sequence.push(addZero((num - i + 10000) % 10000, 4));
    }
    return sequence;
  }
  for (let i = 0; i < length; i++) {
    sequence.push(addZero((num - i + 60) % 60, 2));
  }
  return sequence;

  function addZero(numb, len) {
    if (String(numb).length === len) {
      return numb;
    }
    return "0".repeat(len - String(numb).length) + String(numb);
  }
}
